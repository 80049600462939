<template>
  <nav>
    <v-app-bar app color="primary" dark dense>
      <v-icon>mdi-laptop</v-icon>
      <v-toolbar-title >
        Voice Of Truth
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>person</v-icon>
      </v-btn>

      <v-btn icon @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs align-with-title  >
          <v-tab  v-for="link in filteredMenuItems"
          class="text-capitalize"
            :key="link.text"
            :to="link.route">{{ link.text }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view></router-view>
  </nav>
</template>
<script>
// import { mapState, mapMutations } from "vuex";

// import { baseURL } from "../constant/constant";
// import { mapState } from "vuex";
// import router from "@/router/index";

export default {
  name: "Navbar",
  data() {
    return {
      title: "Home",
      drawer: true,
      group: null,
      model: null,
      search: null,
      menuItems: [
        {
          icon: "home",
          text: "Home",
          route: "/main",
          permissions: ["admin", "user"],
        },
        // {
        //   icon: "mdi-account-group",
        //   text: "Voters",
        //   route: "/voters",
        //   permissions: ["admin", "user"],
        // },
        // {
        //   icon: "mdi-account-group",
        //   text: "Leaders",
        //   route: "/leaders",
        //   permissions: ["admin", "user"],
        // },
        {
          icon: "mdi-account-group",
          text: "Verify Leaders",
          route: "/verifyLeaders",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "States",
          route: "/states",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Districts",
          route: "/districts",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Mandals",
          route: "/mandals",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Villages",
          route: "/villages",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Wards",
          route: "/wards",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "MP constituency",
          route: "/mpconstituencies",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "MLA constituency",
          route: "/mlaconstituencies",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Feedback",
          route: "/feedback",
          permissions: ["admin"],
        },
        {
          icon: "mdi-account-group",
          text: "Report Leaders",
          route: "/reportedLeaders",
          permissions: ["admin"],
        },
        

        // { icon: "mdi-account-group", text: "States", route: "#" },
      ],
    };
  },

  computed: {
    filteredMenuItems() {
      // Filter the menu items based on the user's permissions
      const userRoles = ["admin"]; // replace with actual user roles
      return this.menuItems.filter((item) => {
        return item.permissions.some((permission) =>
          userRoles.includes(permission)
        );
      });
    },
    // ...mapState(["menuTitle"]),
    // getDevices() {
    //   return this.$store.getters.allDevices;
    // },
  },
  mounted() {
    // this.$store.dispatch("getDevices");
  },
  methods: {
    // ...mapMutations(["bindTitle"]),
    logout() {
      this.$store.dispatch('logout');
    },
    // bindNewTitle(title) {
    //   this.bindTitle = title;
    // },
    load() {
      // this.$store.state.selected_deviceID = this.model;
      //this.$forceUpdate();
      // this.$router.go(1);
      //this.$router.push({ path: this.$router.currentRoute.path });
      // location.reload();
    },
  },
};
</script>
