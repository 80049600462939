<template>
  <v-app class="grey lighten-3">
    <!-- <Navbar /> -->
    <!-- <v-snackbar
      v-model="alert.show"
      :color="alert.color"
      top
      right
      class="text-capitalize"
    >
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <v-icon small dark v-bind="attrs" @click="alert.show = false"
          >close</v-icon
        >
      </template>
    </v-snackbar> -->
    <v-main class="grey lighten-3">
      <router-view />
    </v-main>
    <v-footer padless app fixed>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Voice Of Truth</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import Navbar from "./components/navBar";
export default {
  name: "App",
  // components: { Navbar },
  data: () => ({
    //
  }),
  // computed: {
  //   alert() {
  //     return this.$store.getters.loadAlert;
  //   },
  // },
};
</script>
<style scoped>
/* scroll bar style */
::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #757575;
}
.form-label label[for] {
  height: 20px;
  font-size: 10pt;
}
.tableHeader {
  height: 20px;
  font-size: 16px !important;
}
.singleAppbar {
  margin-top: 48px;
}
.doubleAppbar {
  margin-top: 92px;
}
.v-select__slot {
  font-size: smaller;
}
.v-autocomplete__slot {
  font-size: smaller;
}
.v-text-field__slot {
  font-size: smaller;
}
.selectedRow {
  cursor: pointer;
}
</style>
