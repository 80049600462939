
export default {
  computed: {
    selectedStateDisplayName() {
      return this.state_filter.state_name;
    },
  },
  methods: {
    async loadDistricts(state,status) {
      let payload = { state_id: state , status:status};
      this.$store.dispatch("fetchDistricts", payload);
    },
    async loadStates(status) {
      let payload = {  status}
      await this.$store.dispatch("fetchStates",payload);
    },
    async loadFeedbacks() {
      // let payload = {  status}
      await this.$store.dispatch("fetchFeedback");
    },
    async loadReportedLeaders() {
      // let payload = {  status}
      await this.$store.dispatch("fetchReportedLeaders");
    },
    async loadMPconstituencies(district_code,status) {
      let payload = { state_id: district_code,status:status };
      await this.$store.dispatch("fetchMPConstituencies", payload);
    },
    async loadMLAconstituencies(mpconstituency,status) {
      let payload = { state_id: mpconstituency,status:status };
      await this.$store.dispatch("fetchgetMLAConstituencies", payload);
    },
    async loadMandals(district,status) {
      let payload = { district_id: district ,status:status};
      await this.$store.dispatch("fetchMandals", payload);
    },
    async loadVillages(mandal,status) {
      let payload = { mandal_id: mandal ,status:status};
      await this.$store.dispatch("fetchVillages", payload);
    },
    async loadWards(village,status) {
      let payload = { village_id: village ,status:status};
      await this.$store.dispatch("fetchWards", payload);
    },
    async loadNonVerifiedLeaders(){
      this.$store.dispatch("getNonVerifedLeaders");
    },
    closeDialog() {
      this.dialog = false;
      this.resetValidationForm();
      this.resetForm();
    },
    validateForm() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
    },
    resetForm() {
      if (this.$refs.form) {
        return this.$refs.form.reset();
      }
    },
    resetValidationForm() {
      if (this.$refs.form) {
        return this.$refs.form.resetValidation();
      }
    },
    getStatusColor(status) {
      if (status != "Active") return "red";
      else return "green";
    },
    async changeStatus(entityType,entityCode, currentStatus,previous_filter_code) {
      // const load = {
      //   'state_code': await this.loadStates('ALL'),
      //   'district_code': await this.loadDistricts(previous_filter_code,"ALL")
      // }
      const newStatus = currentStatus === 'Active' ? 'Deleted' : 'Active';
      try {
        // Call Vuex action to update the status
        const payload = {
          entityType:entityType,
          entityCode:entityCode,
          newStatus
        }
       await this.$store.dispatch("updateEntityStatus", payload);
        // Optionally, you can refresh the states after updating the status
        // await this.fetchStates();
        if(entityType == 'district_code')  await this.loadDistricts(previous_filter_code,'Active');
        if(entityType == 'mandal_code')  await this.loadMandals(previous_filter_code,'Active');
        if(entityType == 'village_code')  await this.loadVillages(previous_filter_code,'Active');
        if(entityType == 'ward_code')  await this.loadWards(previous_filter_code,'Active');
      } catch (error) {
        console.error(error);
      }
      },
  },
};
