<template>
  <div class="background fill-height">
    <v-card-text class="align-center">
      <v-row>
        <v-col cols="12" md="3" sm="6" :v-for="item in totals">
          <v-card class="mx-auto" rounded="lg">
            <v-list-item three-line>
              <v-list-item-avatar size="60">
                <v-icon size="32" color="green">mdi-account-group</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="button">users</v-list-item-title>
                <v-list-item-subtitle>
                  500
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-card-title>
        Device Information
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6" sm="6">
            <v-card>
              <v-toolbar dense flat>
                <v-toolbar-title class="text-capitalize  subtitle-2 info--text">
                  system Information
                </v-toolbar-title>
              </v-toolbar>
              <v-divider />

              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Manufacturer | Name</td>
                    </tr>
                    <tr>
                      <td>Model</td>
                    </tr>
                    <tr>
                      <td>Android</td>
                    </tr>
                    <tr>
                      <td>Application Version</td>
                    </tr>
                    <tr>
                      <td>IMSI Number</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-card>
              <v-toolbar dense flat>
                <v-toolbar-title class="text-capitalize  subtitle-2 info--text">
                  Other Information
                </v-toolbar-title>
              </v-toolbar>
              <v-divider />

              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Operator</td>
                    </tr>
                    <tr>
                      <td>Cell Number</td>
                    </tr>
                    <tr>
                      <td>Last Event</td>
                    </tr>
                    <tr>
                      <td>Message</td>
                    </tr>
                    <tr>
                      <td>OS Version</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text> -->
      <v-card-title>
        Status
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="n in percentages"
            :key="n.color"
            cols="12"
            md="3"
            sm="6"
          >
            <v-card>
              <v-card-text>
                <div class="text-center">
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="5"
                    :value="n.value"
                    :color="n.color"
                  >
                    {{ n.value }}
                  </v-progress-circular>
                </div>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text block :color="n.color" class="text-capitalize">
                  <v-icon>{{ n.icon }}</v-icon
                  >{{ n.name }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
export default {
  
  name: "Home",

  components: {},
  data: () => ({
    percentages: [
      { color: "teal", value: "90", name: "Battery", icon: "mdi-battery" },
      { color: "red", value: "60", name: "Memory", icon: "mdi-memory" },
      { color: "pink", value: "40", name: "Storage", icon: "sd_storage" },
      { color: "purple", value: "30", name: "Total", icon: "storage" },
    ],
    totals: [
      { text: "Total", value: "500", icon: "mdi-account-group" },
      { text: "Voters", value: "100", icon: "mdi-account-group" },
      { text: "Leaaders", value: "400", icon: "mdi-account-group" },
    ],
  }),
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
