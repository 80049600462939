import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { baseURL } from "../constant/constant";
import router from "../router/index"
import Swal from "sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";


Vue.use(Vuex);

const state = {
  alert: {
    show: false,
    type: "",
    message: "",
  },
  user: null || localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
  isLoggedIn: false || localStorage.getItem('isLoggedIn')?true:false,
  response: "",
  error: null,
  loadingStatus: false,
  states: [],
  districts: [],
  mandals: [],
  villages: [],
  mpConstituencies: [],
  mlaConstituencies: [],
  wards:[],
  menuTitle: "Home",
  nonVerifiedLeaders: [],
  feedback:[],
  reportedLeaders:[]
};

const getters = {
  showAlert: (state) => state.alert.show,
  alertType: (state) => state.alert.type,
  alertMessage: (state) => state.alert.message,
  allStates: (state) =>
    state.states.map((item, index) => ({ ...item, index: index + 1 })),
  allDistricts: (state) =>
    state.districts.map((item, index) => ({ ...item, index: index + 1 })),
  allMandals: (state) =>
    state.mandals.map((item, index) => ({ ...item, index: index + 1 })),
  allVillages: (state) =>
    state.villages.map((item, index) => ({ ...item, index: index + 1 })),
  allWards: (state) =>
    state.wards.map((item, index) => ({ ...item, index: index + 1 })),
  allMpConstituencies: (state) =>
    state.mpConstituencies.map((item, index) => ({
      ...item,
      index: index + 1,
    })),
  allMlaConstituencies: (state) =>
    state.mlaConstituencies.map((item, index) => ({
      ...item,
      index: index + 1,
    })),
  nonVerifiedLeaders: (state) =>
    state?.nonVerifiedLeaders.map((item, index) => ({
      ...item,
      index: index + 1,
    })),
    allFeedback: (state) =>
    state.feedback.map((item, index) => ({
      ...item,
      index: index + 1,
    })),
    allReportedLeaders: (state) =>
    state.reportedLeaders.map((item, index) => ({
      ...item,
      index: index + 1,
    })),
  loadingStatus: (state) => state.loadingStatus,
  loadAlert: (state) => state.alert,
  isLoggedIn: state => state.isLoggedIn,
  getUser:state=>state.user
};

const mutations = {
  updateEntityStatus(state, { entityCode, newStatus }) {
    const stateIndex = state.states.findIndex(state => state.state_code === entityCode);
    if (stateIndex !== -1) {
      state.states[stateIndex].status = newStatus;
    }
  },
  setUser(state, value) {
    state.user = value;
  },
  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
  loadingStatus(state, newLoadingStatus) {
    state.loadingStatus = newLoadingStatus;
  },
  SET_STATES(state, data) {
    state.states = data;
  },
  set_districts(state, data) {
    state.districts =  Array.isArray(data) ? data : [];
  },
  set_mandals(state, data) {
    state.mandals = data;
  },
  set_villages(state, data) {
    state.villages = data;
  },
  set_wards(state, data) {
    state.wards = data;
  },
  set_MPConstituencies(state, data) {
    state.mpConstituencies = data;
  },
  set_MLAConstituencies(state, data) {
    state.mlaConstituencies = data;
  },
  set_nonverifiedleaders(state, data) {
    state.nonVerifiedLeaders = data;
  },
  set_feedback(state, data) {
    state.feedback = data;
  },
  set_reported_leaders(state, data) {
    state.reportedLeaders = data;
  },
  bindTitle(state, newTitle) {
    state.menutitle = newTitle;
  },
  setResponse(state, response) {
    state.response = response;
  },
  setError(state, error) {
    state.error = error;
  },
  setAlert(state, { type, message }) {
    Swal.fire({
      // position: "top-end",
      icon: type,
      title: type,
      text: message,
      // showConfirmButton: false,
      // timer: 1500,
      toast: true,
    });
    // state.alert.show = show;
    // state.alert.type = type;
    // state.alert.message = message;
  },
};
const actions = {
  async login({ dispatch ,commit}, payload) {
    try {
      let response = await axios.post(`${baseURL}admin/login`, payload);
      console.log(response.data);
      commit("set_districts", response.data);
      if (response.status == 201) {
        localStorage.setItem('isLoggedIn',true);
        localStorage.setItem('user',JSON.stringify(response.data));
        commit('setLoggedIn', true);
        commit('setUser', response.data);
        router.push("/")

        
      }
      
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.log("Response Data:", error.response.data);
        console.log("Response Status:", error.response.status);
        console.log("Response Headers:", error.response.headers);
        dispatch("showAlert", {
          type: "error",
          message: `${error.response.data.message}`,
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.log("Error:", error.message);
      }
      console.log("Error Config:", error.config);
    }
  },
  async logout({ commit }){
    localStorage.clear();
    commit('setLoggedIn', false);
    router.push("/login");
  },
  showAlert({ commit }, { type, message }) {
    commit("setAlert", { show: true, type, message });
  },
  hideAlert({ commit }) {
    commit("setAlert", { show: false, type: "", message: "" });
  },
  async fetchStates({ commit },payload) {
    try {
      let response = await axios.get(`${baseURL}location/states`,{
        params: payload
      });
      commit("SET_STATES", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchDistricts({ commit }, payload) {
    try {
      console.log(payload);
      let response = await axios.get(`${baseURL}location/districts`, {
        params: payload,
      });
      commit("set_districts", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMandals({ commit }, payload) {
    try {
      let response = await axios.get(`${baseURL}location/mandals`, {
        params: payload,
      });
      commit("set_mandals", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchVillages({ commit }, payload) {
    try {
      let response = await axios.get(`${baseURL}location/villages`, {
        params: payload,
      });
      commit("set_villages", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchWards({ commit }, payload) {
    try {
      let response = await axios.get(`${baseURL}location/wards`, {
        params: payload,
      });
      commit("set_wards", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMPConstituencies({ commit }, payload) {
    try {
      let response = await axios.get(`${baseURL}admin/state/mp/constituencies`, {
        params: payload,
      });
      commit("set_MPConstituencies", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchgetMLAConstituencies({ commit }, payload) {
    try {
      let response = await axios.get(`${baseURL}admin/state/mla/constituencies`, {
        params: payload,
      });
      commit("set_MLAConstituencies", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getNonVerifedLeaders({ commit }) {
    try {
      let response = await axios.get(`${baseURL}admin/change/requests`);
      commit("set_nonverifiedleaders", response.data?.data?response.data?.data:[]);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFeedback({ commit }) {
    try {
      let response = await axios.get(`${baseURL}admin/feedbacks`);
      commit("set_feedback", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchReportedLeaders({commit}){
    try {
      let response = await axios.get(`${baseURL}admin/reports`);
      commit("set_reported_leaders", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async addNewState({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(`${baseURL}admin/states`, payload);
      console.log(response);
      if (response.status === 201) {
        commit("setResponse", "State added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New state saved successfully`,
        });
      } else {
        commit("setResponse", "Failed to add state");
        dispatch("showAlert", {
          type: "error",
          message: `Failed to add state`,
        });
      }
    } catch (error) {
      console.log(error);
      commit("setResponse", "Failed to add state");
      commit("setError", error.message);
      dispatch("showAlert", {
        type: "error",
        message: `Failed to add state`,
      });
    }
  },
  async addNewDistrict({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(`${baseURL}admin/districts`, payload);
      if (response.status === 201) {
        commit("setResponse", "New District added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New District saved successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message:response.data.message,
        });
      }
    } catch (error) {
      console.log(error,"error");
      if (error.response) {
        dispatch("showAlert", {
          type: "error",
          message: error.response.data?.data?.message,
        });
      }else{
        dispatch("showAlert", {
          type: "error",
          message: 'Something went wrong',
        });
      }

    }
  },
  async addNewMPConstituency({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(
        `${baseURL}admin/mp/constituencies`,
        payload
      );
      if (response.status === 201) {
        commit("setResponse", "New MP Constituency added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New MP Constituency saved successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `New MP constituency ${response} Failed to  save`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async addNewMLAConstituency({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(
        `${baseURL}admin/mla/constituencies`,
        payload
      );
      if (response.status === 201) {
        commit("setResponse", "New MLA Constituency added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New MLA Constituency saved successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `New MLA constituency ${response} Failed to  save`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async addNewMandal({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(`${baseURL}admin/mandals`, payload);
      if (response.status === 201) {
        commit("setResponse", "New Mandal added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New Mandal saved successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `New Mandal ${response.data.mandal_name} Failed to  save`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async addNewVillage({ commit, dispatch }, payload) {
    try {
      let response = await axios.post(`${baseURL}admin/villages`, payload);
      if (response.status === 201) {
        commit("setResponse", "New village added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `New Village saved successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `New Village ${response.data.village_name} Failed to  save`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async addNewWards({commit,dispatch},payload){
    try {
      let response = await axios.put(`${baseURL}admin/extend/wards`,null,{ params: payload } );
      if (response.status === 200) {
        commit("setResponse", "New wards added successfully");
        dispatch("showAlert", {
          type: "success",
          message: `Ward Updated successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `Failed to save data`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async verifyLeaders({ commit, dispatch }, payload) {
    try {
      let response = await axios.put(`${baseURL}admin/action`, payload);
      if (response.status === 200) {
        commit("setResponse", "Leaders Verified Successfully");
        dispatch("showAlert", {
          type: "success",
          message: `Verified Successfully`,
        });
      } else {
        dispatch("showAlert", {
          type: "error",
          message: `Something went wrong! Please try again!`,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch("showAlert", {
        type: "error",
        message: `Something went wrong`,
      });
    }
  },
  async updateEntityStatus({ commit }, payload) {
    try {
      // Make an API call to update the status on the server (if applicable)
      // Example: await ApiService.updateEntityStatus(entityCode, newStatus); entityCode, newStatus
      await axios.put(`${baseURL}admin/updateStatus`, payload);
      // Commit the mutation to update the status in the store
      commit('updateEntityStatus', payload);
    } catch (error) {
      console.error(error);
      // Handle the error, show an alert, etc.
    }
  },

  // setSelectedDeviceID({comit}){

  // }
};
const modules = {};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});
