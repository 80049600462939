import Vue from "vue";
import App from "./App.vue";
import globalMixin from "./helpers/globalMixin";
import router from "./router";
import store from "./store";
import axious from "axios";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.mixin(globalMixin);
Vue.config.productionTip = false;
Vue.prototype.$http = axious;

// Vue.use(require("vue-moment"));
Vue.use(VueSweetalert2);
require("dotenv").config();
new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
