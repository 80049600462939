import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login.vue";
import navbar from "../components/navBar.vue";
import store from "../store/index"

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    component: navbar,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: Home,
      },
      {
        path: "/leaders",
        name: "Leaders",
        component: () => import("../views/leaders.vue"),
      },
      {
        path: "/verifyLeaders",
        name: "VerifyLeaders",
        component: () => import("../views/verifyLeaders.vue"),
      },
      {
        path: "/voters",
        name: "Voters",
        component: () => import("../views/voters.vue"),
      },
      {
        path: "/states",
        name: "States",
        component: () => import("../views/states.vue"),
      },
      {
        path: "/districts",
        name: "Districts",
        component: () => import("../views/districts.vue"),
      },
      {
        path: "/mandals",
        name: "Mandals",
        component: () => import("../views/mandals.vue"),
      },
      {
        path: "/villages",
        name: "Villages",
        component: () => import("../views/villages.vue"),
      },
      {
        path: "/mlaconstituencies",
        name: "Mlaconstituencies",
        component: () => import("../views/mlaconstituencies.vue"),
      },
      {
        path: "/mpconstituencies",
        name: "Mpconstituencies",
        component: () => import("../views/mpconstituencies.vue"),
      },
      {
        path: "/wards",
        name: "wards",
        component: () => import("../views/wards.vue"),
      },
      {
        path: "/feedback",
        name: "Feedback",
        component: () => import("../views/Feedback.vue"),
      },
      {
        path: "/reportedLeaders",
        name: "Reported Leaders",
        component: () => import("../views/reportedLeaders.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    if (store.getters.isLoggedIn) {
      next({ path: "/" });
    } else {
      next();
    }
  }
});

export default router;
