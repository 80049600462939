<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card>
              <v-toolbar color="primary" dark dense
                ><v-toolbar-title
                  >Voice of Truth Login</v-toolbar-title
                ></v-toolbar
              >
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="loginForm.email"
                    placeholder="E-mail"
                    prepend-icon="email"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="loginForm.password"
                    placeholder="Password"
                    prepend-icon="lock"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    dense
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  outlined
                  class="primary--text"
                  @click="handleLoginSubmit"
                  :loading="loading"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
// import router from "@/router/index";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      showPassword: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleLoginSubmit() {
      await this.login(this.loginForm);
      // router.push("/main");
    },
  },
};
</script>
